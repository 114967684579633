import React, { useState, useEffect } from "react";
import IESStackedLine from "../charts/iesStackedLine";
import useStores from "../../hooks/use-stores";
import { observer } from "mobx-react";
import { CHART_WIDTH } from "../../constants/style";
import { Button } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const DISPLAY_METHOD = {
  'byWeek': 0,
  'byMonth': 1,
  'byYear': 2,
}

const AttendenceByClassCodeAdvanceSearch = observer(() => {
  const { attendanceStore } = useStores();
  const { t } = useTranslation();
  const [classCodes, setClassCodes] = useState<any>([]);  // as legends
  const [attendanceRateByWeek, setAttendanceRateByWeek] = useState<any>({});
  const [attendanceTimeByWeek, setAttendanceTimeByWeek] = useState<any>({});
  const [attendanceRateByMonth, setAttendanceRateByMonth] = useState<any>({});
  const [attendanceTimeByMonth, setAttendanceTimeByMonth] = useState<any>({});
  const [displayMethod, setDisplayMethod] = useState(DISPLAY_METHOD.byWeek);
  const [selectedLegend, setSelectedLegend] = useState<any>();
  const [showAllLegend, setShowAllLegend] = useState<boolean>(false);

  const handleDisplayChange = (method) => {
    setDisplayMethod(method);
    setShowAllLegend(false);
    onChangeSelectedLegend(classCodes, false);
  }

  useEffect(() => {
    if (attendanceStore.attendanceAdvanceData.length > 0) {
      let tempClassCodes: any[] = [];
      let tempWeeks: any[] = [];
      let tempMonths: any[] = []
      let tempWeeklyRates: any[] = [];
      let tempMonthlyRates: any[] = []
      attendanceStore.attendanceAdvanceData.map((d) => {
        tempClassCodes.push(d.homeRoom + '-' + d.domain);
      })
      attendanceStore.attendanceAdvanceData[0].weekly.map((d) => {
        tempWeeks.push(d.label);
      })
      attendanceStore.attendanceAdvanceData[0].monthly.map((d) => {
        tempMonths.push(d.label);
      })
      attendanceStore.attendanceAdvanceData.map((d) => {
        let wrates: any[] = [];
        let mrates: any[] = [];
        d.weekly.map((record) => {
          let r = record.amountString;
          // if r === "" or other non-numeric value, then push 0
          if (!isNaN(parseFloat(r)) && isFinite(r)) {
            wrates.push(parseFloat(r));
          }
          else {
            wrates.push(0);
          }
        })
        tempWeeklyRates.push(wrates);
        d.monthly.map((record) => {
          let r = record.amountString;
          // if r === "" or other non-numeric value, then push 0
          if (!isNaN(parseFloat(r)) && isFinite(r)) {
            mrates.push(parseFloat(r));
          }
          else {
            mrates.push(0);
          }
        })
        tempMonthlyRates.push(mrates);
      })
      setShowAllLegend(false);
      setClassCodes(tempClassCodes);
      onChangeSelectedLegend(tempClassCodes, false);
      setAttendanceTimeByWeek(tempWeeks);
      setAttendanceTimeByMonth(tempMonths);
      setAttendanceRateByWeek(tempWeeklyRates);
      setAttendanceRateByMonth(tempMonthlyRates);
      /*
      let tempClassCodes = [];
      let tempDomains = [];
      let tempRates = [];
      let domainSet = new Set();
      attendanceStore.attendanceAdvanceData.map((d) => {
        tempClassCodes.push(d.homeRoom);
        d.detailData.map((detail) => {
          domainSet.add(detail.label);
        })
      })
      tempDomains = Array.from(domainSet);
      attendanceStore.attendanceAdvanceData.map((d) => {
        let rates = [];
        tempDomains.map((domain) => {
          const res = d.detailData.filter(record => record.label == domain);
          res.length === 0 ? rates.push(0) : rates.push(res[0].amount);
        })
        tempRates.push(rates)
      })
      */
      //setClassCodes(tempClassCodes);
    }
  }, [attendanceStore.attendanceAdvanceData]);

  const onChangeSelectedLegend = (tempLegend: any, showAllLegend: boolean) => {
    let selected = {};

    let index = 0;
    for (var legend of tempLegend) {
      if (showAllLegend) {
        selected[legend] = true;
      } else {
        if (legend != tempLegend[0] && legend != tempLegend[1]) selected[legend] = false;
        else selected[legend] = true;
      }
      index++;
    }
    setSelectedLegend(selected);
    console.log(selected);
  }

  return (
    attendanceStore.attendanceAdvanceData.length ?
      <div className="my-10">
        <div className="text-right">
          <Button
            className={`rounded-lg text-white bg-barPrimary text-sm m-4 p-2`}
            component="span"
            onClick={() => {
              if (showAllLegend) onChangeSelectedLegend(classCodes, false);
              else onChangeSelectedLegend(classCodes, true);
              setShowAllLegend(!showAllLegend);
            }}
          >
            {showAllLegend ? t('SHOW_ONLY_FIRST_TWO_SUBJECTS') : t('SHOW_ALL_SUBJECTS')}
          </Button>
          <button
            className={`mx-2 p-2 rounded-lg text-sm text-white ${displayMethod == DISPLAY_METHOD.byWeek ? 'bg-gray-400' : ' bg-barPrimary'}`}
            onClick={() => handleDisplayChange(DISPLAY_METHOD.byWeek)}>
            {t('BY_WEEK')}
          </button>
          <button
            className={`mr-2 p-2 rounded-lg text-sm text-white ${displayMethod == DISPLAY_METHOD.byMonth ? 'bg-gray-400' : ' bg-barPrimary'}`}
            onClick={() => handleDisplayChange(DISPLAY_METHOD.byMonth)}>
            {t('BY_MONTH')}
          </button>
        </div>
        {
          displayMethod === DISPLAY_METHOD.byWeek &&
          <IESStackedLine
            dataAndSetting={{
              data: {
                legends: classCodes,
                names: attendanceTimeByWeek.length > 0 ? attendanceTimeByWeek : ["No Result"],
                values: attendanceRateByWeek.length > 0 ? attendanceRateByWeek : [[0]],
              },
              height: classCodes.length > 20 ? 650 : 500,
              width: CHART_WIDTH,
              selectedLegend: selectedLegend,
              top: classCodes.length > 20 ? `${classCodes.length + 5}%` : '30%',
              xAxisLabelRotate: 30,
              xAxisLabelInterval: 1,
              xAxisLabelFontSize: 12,
            }}
          />
        }
        {
          displayMethod === DISPLAY_METHOD.byMonth &&
          <IESStackedLine
            dataAndSetting={{
              data: {
                legends: classCodes,
                names: attendanceTimeByMonth.length > 0 ? attendanceTimeByMonth : ["No Result"],
                values: attendanceRateByMonth.length > 0 ? attendanceRateByMonth : [[0]],
              },
              height: classCodes.length > 20 ? 650 : 500,
              width: CHART_WIDTH,
              top: classCodes.length > 20 ? `${classCodes.length + 5}%` : '30%',
              selectedLegend: selectedLegend,
              xAxisLabelRotate: 30,
              xAxisLabelInterval: 1,
              xAxisLabelFontSize: 12,
            }}
          />
        }
        <br /><br /><br /><br />
      </div>
      : <div className="w-full text-barPrimary text-3xl text-center p-10">{t('CHART_AREA')}</div>
  )
})

export default AttendenceByClassCodeAdvanceSearch;