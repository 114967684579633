import React, { useEffect, useState } from 'react';
import { StoreProvider } from "../../stores/store-context";
import { Tabs, Tab } from "@material-ui/core";
import Layout from "../../components/shared-layout";
import TabPanel from '../../components/tab-panel';
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import AttendenceByClassCode from '../../components/attendence/attendenceByClassCode';


const AttendencePage = observer(() => {

  const { attendanceStore, filterStore } = useStores();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [innerValue, setInnerValue] = React.useState(2);
  const concatTitle = () => {
    filterStore.attendanceTitleFirstLine =
      `${t('YEAR')}: ` + filterStore.selectedYear + ` - ${t('SEMESTER')}: ` + filterStore.selectedSemester + ` - ${t('HOMEROOM_CODE')}: ` + filterStore.selectedHomeroom;
    filterStore.attendanceTitleSecondLine =
      `${t('DOMAIN')}: ` + filterStore.selectedDomain + ` - ${t('SUBJECT')}: ` + filterStore.selectedSubject + ` - ${t('CLASS')}: ` + filterStore.selectedClass;
  }

  useEffect(() => {
    filterStore.getSemesterDateList().then(() => {
      if (filterStore.currentSemester == 0) filterStore.getCurrentSemester();
      let lastSemester = filterStore.currentSemester;
      let yearDescription = "";
      let semesterDescription = "";
      let startDate: any = "";
      let endDate: any = "";
      for (var year of filterStore.academicYearList) {
        if (year.academicSemesters.find(s => s.id == lastSemester)) {
          yearDescription = year.yearDescription;
          let semesterObj = year.academicSemesters.find(s => s.id == lastSemester);
          semesterDescription = semesterObj.semesterDescription;
          startDate = semesterObj.academicTerms[0].academicTermDates.find((data) => data.yearLevelId > 0).startDate;
          endDate = semesterObj.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
          break;
        }
      }

      let reqData = {
        semesterIdList: [lastSemester],
        startDate,
        endDate,
        homeRoomList: ["9A"],
        domainIdList: [2],
        subjectClassIdList: [],
      }
      filterStore.selectedYear = yearDescription;
      filterStore.selectedSemester = semesterDescription;
      filterStore.selectedHomeroom = "9A";
      filterStore.selectedDomain = "English";
      filterStore.selectedSubject = t('ALL');
      filterStore.selectedClass = t('ALL');
      concatTitle();
      attendanceStore.getAttendanceByClassCodes(reqData)
        .then((res) => {
          if(res.length == 0) {
            lastSemester = filterStore.currentSemester - 1;
            for (var year of filterStore.academicYearList) {
              if (year.academicSemesters.find(s => s.id == lastSemester)) {
                yearDescription = year.yearDescription;
                let semesterObj = year.academicSemesters.find(s => s.id == lastSemester);
                semesterDescription = semesterObj.semesterDescription;
                startDate = semesterObj.academicTerms[0].academicTermDates.find((data) => data.yearLevelId > 0).startDate;
                endDate = semesterObj.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
                break;
              }
            }
            let reqData = {
              semesterIdList: [lastSemester],
              startDate,
              endDate,
              homeRoomList: ["9A"],
              domainIdList: [2],
              subjectClassIdList: [],
            }
            filterStore.selectedYear = yearDescription;
            filterStore.selectedSemester = semesterDescription;
            attendanceStore.getAttendanceByClassCodes(reqData);
            concatTitle();
          }
        });

      // filterStore.getSemesterBasedOnYear(new Date().getFullYear().toString());

      // var selectedSemester = filterStore.semesterList.find(e => e.id == lastSemester);
      // if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
      //   var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
      //   var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
      //   let reqData = {
      //     semesterIdList: [lastSemester],
      //     startDate: startTerm.startDate,
      //     endDate: endTerm.endDate,
      //     homeRoomList: ["9A"],
      //     domainIdList: [2],
      //     subjectClassIdList: [],
      //   }
      //   filterStore.selectedYear = new Date().getFullYear().toString();
      //   filterStore.selectedSemester = filterStore.academicYearList
      //     .filter((a) => a.yearDescription == filterStore.selectedYear)[0].academicSemesters
      //     .filter((sem) => sem.id == lastSemester)[0].semesterDescription
      //   filterStore.selectedHomeroom = "9A";
      //   filterStore.selectedDomain = "English";
      //   filterStore.selectedSubject = t('ALL');
      //   filterStore.selectedClass = t('ALL');
      //   concatTitle();
      //   attendanceStore.getAttendanceByClassCodes(reqData);
      // }

    })
  }, []);

  const onApplyFilter = (req: any) => {
    concatTitle();
    var tempSubjectClassIdList = []
    if (req.subject !== 0) {
      if (req.subjectClass !== 0) {
        tempSubjectClassIdList.push(req.subjectClass);
      }
      else {
        filterStore.subjectClassList.map(e => {
          tempSubjectClassIdList.push(e.id);
        });
      }
    }



    let reqData = {
      semesterIdList: req.semesterList,
      startDate: req.startDate,
      endDate: req.endDate,
      homeRoomList: req.classCodeFullList.length > 1 ? [] : req.classCodeFullList,
      domainIdList: req.domainIdList,
      subjectClassIdList: tempSubjectClassIdList,
    }
    console.log(reqData);
    attendanceStore.getAttendanceByClassCodes(reqData);
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={true}
          showYearSemTerm={true}
          showClassCode={true}
          showDomain={true}
          showSubjectAndSubjectClass={true}
          showStaffList={false}
          defaultDomain={"English"}
          defaultHomeroomCode={"9A"}
          onApplyFilter={onApplyFilter}
        >
          <title>{t('ATTENDANCE')}</title>
          <div className="col-span-4 flex flex-col mt-2">
            {/* 
        <div className="mx-2 mt-2 pl-4 py-2 bg-white border-b-2 border-barPrimary">
          <Tabs value={value}
            onChange={handleOuterChange}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }} >
            <Tab label="Attendence" {...a11y1Props(0, 0 === value)} />]
          </Tabs>
        </div>
        */}

            <TabPanel value={value} paddingValue={0} index={0}>
              {/* Attendence */}
              {/*
          <div className="mx-2 py-2 bg-white">
            <Tabs value={innerValue}
              onChange={handleInnerChange}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }} >
              <Tab label="By Year" {...a11y2Props(0, 0 === innerValue)} />
              <Tab label="By Domain" {...a11y2Props(1, 1 === innerValue)} />
              <Tab label="By ClassCode" {...a11y2Props(2, 2 === innerValue)} />
            </Tabs>
          </div>
            */}

              <TabPanel value={innerValue} paddingValue={0} index={0}>
                {/* By Year */}
                <div className="text-barPrimary p-4 text-xl font-bold mb-4 text-white grid grid-cols-2">
                  <div className="py-2">Attendence Rate</div>
                  <div className="text-right">
                    <button className="p-2 2xl:px-4 bg-advancedSearchButton text-sm 2xl:text-md">
                      {t('ADVANCED_SEARCH')}
                    </button>
                  </div>
                </div>

              </TabPanel>

              <TabPanel value={innerValue} paddingValue={0} index={1}>
                {/* By Domain */}
                <div className="text-barPrimary p-4 text-xl font-bold mb-4 text-white grid grid-cols-2">
                  <div className="py-2">Attendence Rate</div>
                  <div className="text-right"><button className="px-4 py-2 bg-advancedSearchButton">{t('ADVANCED_SEARCH')}</button></div>
                </div>

              </TabPanel>

              <TabPanel value={innerValue} paddingValue={0} index={2}>
                {/* By ClassCode */}
                <AttendenceByClassCode />
              </TabPanel>
            </TabPanel>


          </div>
        </Layout>
      }
    </>
  )
})

export default AttendencePage;