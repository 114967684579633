import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ReactEcharts from 'echarts-for-react';

const IESStackedLine = observer(({ dataAndSetting }) => {
  const [selectedLegend, setSelectedLegend] = useState<any>();
  const [option, setOption] = useState({});
  const [loading, setLoading] = useState(true);

  const configureOption = (data, setting) => {

    var series_ = []

    for (var i = 0, len = data.legends.length; i < len; i++) {
      var serie = {
        name: data.legends[i],
        type: 'line',
        lineStyle: {
          width: setting.lineWidth,
        },
        symbolSize: setting.symbolSize,
        data: data.values[i]
      }
      series_.push(serie);
    }

    var option_ = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: data.legends,
        selectorLabel: { show: false },
        selected: dataAndSetting.selectedLegend
      },
      grid: {
        left: 50,
        right: 30,
        top: dataAndSetting.top ? dataAndSetting.top : '30%'
      },
      xAxis: {
        type: 'category',
        data: data.names,
        axisLabel: {
          interval: setting.xAxisLabelInterval,
          rotate: setting.xAxisLabelRotate,
          fontSize: setting.xAxisLabelFontSize,
          fontFamily: setting.xAxisLabelFontFamily,
          color: setting.xAxisLabelColor
        }
      },
      yAxis: {
        name: "Rate (%)",
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            width: 1.2,
          },
        },
      },
      series: series_
    };

    setOption(option_);
    setLoading(true);
  }

  useEffect(() => {
    setLoading(false);
    setSelectedLegend(dataAndSetting.selectedLegend);
    const {
      lineWidth = 1,
      symbolSize = 10,
      xAxisLabelRotate = 0,
      xAxisLabelInterval = 0,
      xAxisLabelFontSize = 15,
      xAxisLabelFontFamily = 'monospace',
      xAxisLabelColor = '#171717'
    } = dataAndSetting
    configureOption(dataAndSetting.data, {
      lineWidth,
      symbolSize,
      xAxisLabelRotate,
      xAxisLabelInterval,
      xAxisLabelFontSize,
      xAxisLabelFontFamily,
      xAxisLabelColor,
    })
  }, [dataAndSetting]);


  return (
    <>
      {
        loading &&
        <ReactEcharts
          style={{
            height: dataAndSetting.height,
            width: dataAndSetting.width,
            margin: '0 auto',
          }}
          option={option}
        />
      }

    </>
  );
})

export default IESStackedLine;
