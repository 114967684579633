import React, { useEffect, useState } from 'react';
import useStores from "../../hooks/use-stores";
import { observer } from "mobx-react";
import { Button, CircularProgress } from "@material-ui/core";
import { CHART_HEIGHT, CHART_WIDTH } from "../../constants/style";
import IESStackedLine from "../charts/iesStackedLine";
import AdvanceSearchModal from '../advance-search-modal';
import AttendenceByClassCodeAdvanceSearch from './attendenceByClassCodeAdvanceSearch';
import { useTranslation } from 'react-i18next';
import VMCard from '../collapsed-card';

const DISPLAY_METHOD = {
  'byWeek': 0,
  'byMonth': 1,
  'byYear': 2,
}

const AttendenceByClassCode = observer(() => {
  const { attendanceStore, filterStore } = useStores();
  const { t } = useTranslation();
  const [domainLegends, setDomainLegends] = useState<any>([]);
  const [attendanceRateByWeek, setAttendanceRateByWeek] = useState<any>({});
  const [attendanceTimeByWeek, setAttendanceTimeByWeek] = useState<any>({});
  const [attendanceRateByMonth, setAttendanceRateByMonth] = useState<any>({});
  const [attendanceTimeByMonth, setAttendanceTimeByMonth] = useState<any>({});
  const [showAllLegend, setShowAllLegend] = useState<boolean>(false);
  const [selectedLegend, setSelectedLegend] = useState<any>();
  const [showAdvanceSearch, setShowAdvanceSearch] = useState<boolean>(false);
  const [displayMethod, setDisplayMethod] = useState(DISPLAY_METHOD.byWeek);

  const handleDisplayChange = (method) => {
    setDisplayMethod(method);
    setShowAllLegend(false);
    onChangeSelectedLegend(domainLegends, false);
  }

  const onApplyAdvanceSearch = (dataInfo: any) => {

    var finalClassList;
    if (!dataInfo.isDateRange) {
      if (dataInfo.classCodeList.length == 1 && dataInfo.classCodeList[0] == "All") {
        var tempList = [];
        for (var c of filterStore.classList) {
          tempList.push(c.homeroomCode);
        }
        finalClassList = tempList;
      }
      else
        finalClassList = dataInfo.classCodeList;
    }

    var finalDomainList;
    if (dataInfo.domainList.length == 1 && dataInfo.domainList[0] == "All") {
      var tempList = [];
      for (var d of filterStore.domainList) {
        tempList.push(d.id);
      }
      finalDomainList = tempList;
    }
    else {
      finalDomainList = dataInfo.domainList;
    }

    let reqData = {
      semesterIdList: dataInfo.semester ? [dataInfo.semester] : [0],
      useDateRange: dataInfo.isDateRange ? 1 : 0,
      startDate: dataInfo.isDateRange ? dataInfo.fromDate : dataInfo.startDate,
      endDate: dataInfo.isDateRange ? dataInfo.toDate : dataInfo.endDate,
      homeRoomList: dataInfo.isDateRange ? [] : finalClassList,
      domainIdList: finalDomainList,
      subjectClassIdList: []
    }
    console.log(reqData);

    attendanceStore.getAdvanceAttendanceByClassCodes(reqData);
  }

  useEffect(() => {
    if (attendanceStore.attendanceData.length <= 0) {
      return;
    }
    let tempLegends = [];
    let tempWeeks = [];
    let tempMonths = []
    let tempWeeklyRates = [];
    let tempMonthlyRates = []
    attendanceStore.attendanceData.map((d) => {
      tempLegends.push(d.domain);
    });
    attendanceStore.attendanceData[0].weekly.map((d) => {
      tempWeeks.push(d.label);
    });
    attendanceStore.attendanceData[0].monthly.map((d) => {
      tempMonths.push(d.label);
    });

    attendanceStore.attendanceData.map((d) => {
      let wrates = [];
      let mrates = [];

      d.weekly.map((record) => {
        let r = record.amountString;
        // if r === "" or other non-numeric value, then push 0
        if (!isNaN(parseFloat(r)) && isFinite(r)) {
          wrates.push(parseFloat(r));
        }
        else {
          wrates.push(0);
        }
      })
      tempWeeklyRates.push(wrates);
      d.monthly.map((record) => {
        let r = record.amountString;
        // if r === "" or other non-numeric value, then push 0
        if (!isNaN(parseFloat(r)) && isFinite(r)) {
          mrates.push(parseFloat(r));
        }
        else {
          mrates.push(0);
        }
      })
      tempMonthlyRates.push(mrates);
    });

    setShowAllLegend(false);
    setDomainLegends(tempLegends);
    onChangeSelectedLegend(tempLegends, false);
    setAttendanceTimeByWeek(tempWeeks);
    setAttendanceTimeByMonth(tempMonths);
    setAttendanceRateByWeek(tempWeeklyRates);
    setAttendanceRateByMonth(tempMonthlyRates);

  }, [attendanceStore.attendanceData]);

  const onChangeSelectedLegend = (tempLegend: any, showAllLegend: boolean) => {
    let selected = {};

    let index = 0;
    for (var legend of tempLegend) {
      if (showAllLegend) {
        selected[legend] = true;
      } else {
        if (legend != tempLegend[0] && legend != tempLegend[1]) selected[legend] = false;
        else selected[legend] = true;
      }
      index++;
    }
    setSelectedLegend(selected);
  }

  return (
    <div>
      {
        attendanceStore.loading || filterStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
          :
          <div className="pb-8" style={{ minHeight: '100vh' }}>
            <div className="rounded-xl shadow-lg border text-barPrimary p-4 text-xl font-bold mb-4 text-white grid grid-cols-2">
              <div className="py-2 pl-4 uppercase">{t('ATTENDANCE')}</div>
              <div className="text-right">
                <Button
                  className="bg-buttonPrimary text-white p-2"
                  component="span"
                  onClick={() => setShowAdvanceSearch(true)}
                >
                  {t('ADVANCED_SEARCH')}
                </Button>
              </div>
            </div>

            <VMCard titleClassName='mt-4'>
              <div className="mt-4 flex flex-col">
                <div className="text-barPrimary p-4 text-xl font-bold text-center">
                  {filterStore.attendanceTitleFirstLine}
                </div>
                <div className="text-barPrimary p-4 text-xl font-bold text-center">
                  {filterStore.attendanceTitleSecondLine}
                </div>

                <div className="text-right">
                  <Button
                    className={`rounded-lg text-white bg-barPrimary text-sm m-4`}
                    component="span"
                    onClick={() => {
                      if (showAllLegend) onChangeSelectedLegend(domainLegends, false);
                      else onChangeSelectedLegend(domainLegends, true);
                      setShowAllLegend(!showAllLegend);
                    }}
                  >
                    {showAllLegend ? t('SHOW_ONLY_FIRST_TWO_SUBJECTS') : t('SHOW_ALL_SUBJECTS')}
                  </Button>
                  <Button
                    className={`px-2 rounded-lg text-sm text-white uppercase ${displayMethod == DISPLAY_METHOD.byWeek ? 'bg-barPrimary' : ' bg-gray-400'}`}
                    onClick={() => handleDisplayChange(DISPLAY_METHOD.byWeek)}>
                    {t('BY_WEEK')}
                  </Button>
                  <Button
                    className={`mx-2 px-2 rounded-lg text-sm text-white uppercase ${displayMethod == DISPLAY_METHOD.byMonth ? 'bg-barPrimary' : ' bg-gray-400'}`}
                    onClick={() => handleDisplayChange(DISPLAY_METHOD.byMonth)}>
                    {t('BY_MONTH')}
                  </Button>
                </div>
                {
                  displayMethod === DISPLAY_METHOD.byWeek && selectedLegend != undefined &&
                  <IESStackedLine
                    dataAndSetting={{
                      data: {
                        legends: domainLegends,
                        names: attendanceTimeByWeek.length > 0 ? attendanceTimeByWeek : [t('NO_RESULT')],
                        values: attendanceRateByWeek.length > 0 ? attendanceRateByWeek : [[0]],
                      },
                      selectedLegend: selectedLegend,
                      height: 400,
                      width: CHART_WIDTH,
                      xAxisLabelRotate: 30,
                      xAxisLabelInterval: 1,
                      xAxisLabelFontSize: 12,
                    }}
                  />
                }

                {
                  displayMethod === DISPLAY_METHOD.byMonth && selectedLegend &&
                  <IESStackedLine
                    dataAndSetting={{
                      data: {
                        legends: domainLegends,
                        names: attendanceTimeByMonth.length > 0 ? attendanceTimeByMonth : [t('NO_RESULT')],
                        values: attendanceRateByMonth.length > 0 ? attendanceRateByMonth : [[0]],
                      },
                      selectedLegend: selectedLegend,
                      height: 400,
                      width: CHART_WIDTH,
                      xAxisLabelRotate: 30,
                      xAxisLabelFontSize: 12,
                    }}
                  />
                }

              </div>
            </VMCard>

            <AdvanceSearchModal
              showModal={showAdvanceSearch}
              closeModal={() => setShowAdvanceSearch(false)}
              showAcademicYear={true}
              showSemester={true}
              showYearLevel={true}
              otherFilter={["domain", "attendance"]}
              onApplyFilter={onApplyAdvanceSearch}
            >
              {
                attendanceStore.advanceLoading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <AttendenceByClassCodeAdvanceSearch />
              }

            </AdvanceSearchModal>
          </div>
      }
    </div>
  )
})

export default AttendenceByClassCode;